import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Module as AssistantModule } from "@workforcesoftware/assistant-ui";
import {
  AppPanel,
  createAndInitializeLighthouseFramework,
  FrameworkConfigPropertyNames,
  FrameworkConfigProperties,
  DefaultProperties,
  DefaultPropertyNames,
  getIsAppEmbeddedFromSearchParams,
  isMobileDevice,
  DEEPLINK_HANDLER_ROUTE
} from "@workforcesoftware/lighthouse-framework";
import { HubModule } from "@workforcesoftware/hub-ui";
import { Module as TimeModule } from "@workforcesoftware/time-ui";
import { Module as TimeOffModule } from "@workforcesoftware/time-off-ui";
import { Module as ScheduleModule } from "@workforcesoftware/scheduling-ui";
import { Module as ClockModule } from "@workforcesoftware/clock-ui";
import { Module as ConfigurationModule } from "@workforcesoftware/configuration-ui";
import { Module as EmployeeGroupManagementModule } from "@workforcesoftware/employee-groups-ui";
import { Module as MessagingModule } from "@workforcesoftware/messaging-ui";
import { Module as AvailabilityModule } from "@workforcesoftware/availability-ui";
import { module as TimesheetModule } from "@workforcesoftware/timesheet-ui";
import { Module as NavigationModule } from "@workforcesoftware/navigation-ui";
import { module as TimesheetApprovalModule } from "@workforcesoftware/timesheet-approval-ui";
import { Module as ShiftManagementModule } from "@workforcesoftware/shift-management-ui";
import {
  ScheduleEditorModule,
  CalendarViewModule,
} from "@workforcesoftware/herald-ui";
import { Module as BankBalancesModule } from "@workforcesoftware/bank-balances-ui";
import { Module as ScheduleTemplateModule } from "@workforcesoftware/schedule-template-ui";
import { BroadcastsModule, TasksAssigneeModule } from "@workforcesoftware/experience";

FrameworkConfigProperties.set(
  FrameworkConfigPropertyNames.serviceBaseURL,
  process.env.REACT_APP_FRONTEND_API_URL || ""
);

DefaultProperties.set(
  DefaultPropertyNames.organization,
  process.env.REACT_APP_DEFAULT_ORGANIZATION || ""
);

DefaultProperties.set(
  DefaultPropertyNames.username,
  process.env.REACT_APP_DEFAULT_USERNAME || ""
);

const modules = [
  HubModule,
  AssistantModule,
  TimeModule,
  ScheduleModule,
  EmployeeGroupManagementModule,
  TimeOffModule,
  ClockModule,
  ConfigurationModule,
  MessagingModule,
  AvailabilityModule,
  TimesheetModule,
  NavigationModule,
  TimesheetApprovalModule,
  ShiftManagementModule,
  ScheduleEditorModule,
  CalendarViewModule,
  BankBalancesModule,
  ScheduleTemplateModule,
  BroadcastsModule,
  TasksAssigneeModule,
];

const searchParams = new URLSearchParams(window.location.search);
const isAppEmbedded = getIsAppEmbeddedFromSearchParams(searchParams);

const framework = createAndInitializeLighthouseFramework(
  modules,
  {
    bankBalances: {
      serviceBaseURL: process.env.REACT_APP_BANK_BALANCE_BASE_URL,
    },
    shifts: {
      serviceBaseURL: process.env.REACT_APP_SHIFTS_BASE_URL,
    },
    userPreferences: {
      serviceBaseURL: process.env.REACT_APP_USER_PREFERENCES_BASE_URL,
    },
    authentication: {
      serviceBaseURL: process.env.REACT_APP_AUTHENTICATION_BASE_URL,
    },
    clock: {
      serviceBaseURL: process.env.REACT_APP_CLOCK_BASE_URL,
    },
    wtaBaseUrl: {
      serviceBaseURL: process.env.REACT_APP_WTA_BASE_URL,
    },
    fsBaseUrl: {
      serviceBaseURL: process.env.REACT_APP_FS_BASE_URL,
    },
    urlOverrides: {
      localServiceUrls: process.env.REACT_APP_LOCAL_SERVICE_URLS,
    },
    walkme: {
      snippet: process.env.REACT_APP_WALKME_SNIPPET,
    },
    uiTestingMode: {
      isUiTestingMode: process.env.REACT_APP_RUNNING_UI_TESTS,
    },
  },
  {
    isAppEmbedded,
  }
);

/**
 * Sets the `redirectRoute` in the framework's state
 * so that the framework can redirect to the appropriate route based on whether
 * or not the `link` is a deep-link.
 * If the `link` is a deep-link (contains `/launch`), then encodes the `link` before setting
 * it as the redirect route for the framework.
 * @param link
 * @returns
 */
function setFrameworkLandingRoute(link: string) {
  if (!link) return;
  if (link.includes(DEEPLINK_HANDLER_ROUTE)) {
    framework.setRedirectRoute(getEncodedDeeplinkUrl(link));
    // Because the react app uses a hash-like router (/#/), the deeplink path
    // ends up twice in the URL unless the location URL is manually updated to the origin.
    history.pushState({}, "", window.location.origin);
  } else {
    framework.setRedirectRoute(link);
  }
}

// If the app is being rendered as a mobile app (cordova),
// listen to the "handleDeeplinkEvent" triggered by the cordova deeplinks plugin via
// the index.html file and appropriately set the landing route for the framework.
window.addEventListener("handleDeeplinkEvent", (event: any) => {
  const link: string = event.detail;
  setFrameworkLandingRoute(link);
});

// For web, set the current pathname as the landing route (`redirectRoute`) for the framework.
// For cordova, the `isMobileDevice` has a tendency of returning undefined because this code
// runs before the cordova device is ready. Hence, another way to ensure that this code isn't running on
// cordova is to verify that the pathname doesn't start with /index.html because cordova loads 
// the app at https://wfs.cloud/index.html.
// No need to set the redirect route if there is no pathname in the URL.
const pathname = window.location.pathname;
const pathParts = pathname?.split("/");
if (
  !isMobileDevice() &&
  pathParts?.length > 1 &&
  pathParts[1] &&
  pathParts[1] !== "index.html"
) {
  setFrameworkLandingRoute(window.location.pathname);
}
const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={framework.getStore()}>
    <AppPanel rootElement={rootElement} lazyLoadModules={lazyLoadModules} framework={framework} />
  </Provider>,
  rootElement
);

function getEncodedDeeplinkUrl(deepLinkUrl: string): string {
  const frameworkDeeplinkHandlerRoute = `${DEEPLINK_HANDLER_ROUTE}/`;
  return `${frameworkDeeplinkHandlerRoute}${encodeURIComponent(
    deepLinkUrl.replace(frameworkDeeplinkHandlerRoute, "")
  )}`;
}

// This handler is guaranteed to only get called after the AppPanel has finished app initialization.
// Therefore, framework.getNamedFeatureFlags can be safely called here.
async function lazyLoadModules() {
  return [];
}
